<div class="body">  
  <div class="body-row row">
    <aside class="col-md-3">
      <h1>{{ title }} <a href="mailto:bytanyar@yahoo.com">bytanyar@yahoo.com</a></h1>
    </aside>
    <div class="col-md-9">
      <div id="mainNav" class="header-container row">
        <div class="constrainer">
          <div class="main-header">
            <nav>
              <a routerLink="/">Home</a>
              <a routerLink="/artwork">Artwork</a>
              <a routerLink="/cats">Cats</a>
              <a target="_blank" href="https://github.com/bytanyar/bytanyar.github.io">GitHub</a>
              <a href="https://bytanyar.com/resume-2022/">React</a>
            </nav>
          </div>  
        </div>
      </div>
      <div id="mainContainer" class="main-container">
        <div class="">
          <router-outlet></router-outlet>
        </div>
      </div>
      <footer>
        <div class="copyright">
          Copyright 2022 ByTanyaR
        </div>
      </footer>
    </div>
  </div>
</div>