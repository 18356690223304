import { Artwork } from '../classes/artwork';

export const DRAWINGS: Artwork[] = [
  { id: 1,
    specificId: 'drawing1', 
    image: 'assets/images/artwork/drawings/Fritz - 72.jpg', 
    alt: 'Fritz', 
    thumbImage: 'assets/images/artwork/drawings/Fritz - 72.jpg', 
    title: 'Fritz'
  },
  { id: 2,
    specificId: 'drawing2', 
    image: 'assets/images/artwork/drawings/Lovers -72.jpg', 
    alt: 'Lovers', 
    thumbImage: 'assets/images/artwork/drawings/Lovers -72.jpg', 
    title: 'Lovers'
  },
  { id: 3,
    specificId: 'drawing3', 
    image: 'assets/images/artwork/drawings/Kitten - 72.jpg', 
    alt: 'Kitten', 
    thumbImage: 'assets/images/artwork/drawings/Kitten - 72.jpg', 
    title: 'Kitten'
  },
  { id: 4,
    specificId: 'drawing4', 
    image: 'assets/images/artwork/drawings/Zorro - 72.jpg', 
    alt: 'Zorro', 
    thumbImage: 'assets/images/artwork/drawings/Zorro - 72.jpg', 
    title: 'Zorro'
  },
  { id: 5,
    specificId: 'drawing5', 
    image: 'assets/images/artwork/drawings/Tanya - 72.jpg', 
    alt: 'Tanya', 
    thumbImage: 'assets/images/artwork/drawings/Tanya - 72.jpg', 
    title: 'Tanya'
  },
  { id: 6,
    specificId: 'drawing6', 
    image: 'assets/images/artwork/drawings/Man head dwg - 72.jpg', 
    alt: 'Man head', 
    thumbImage: 'assets/images/artwork/drawings/Man head dwg - 72.jpg', 
    title: 'Man head'
  },
  { id: 7,
    specificId: 'drawing7', 
    image: 'assets/images/artwork/drawings/Squiggle Man Reclined - 72.jpg', 
    alt: 'Squiggle man reclined', 
    thumbImage: 'assets/images/artwork/drawings/Squiggle Man Reclined - 72.jpg', 
    title: 'Squiggle man reclined'
  },
  { id: 8,
    specificId: 'drawing8', 
    image: 'assets/images/artwork/drawings/WWII prep - 72.jpg', 
    alt: 'WWII prep', 
    thumbImage: 'assets/images/artwork/drawings/WWII prep - 72.jpg', 
    title: 'WWII prep'
  }
]

export const PAINTINGS: Artwork[] = [
    { id: 1,
      specificId: 'painting1', 
      image: 'assets/images/artwork/paintings/Angel Fish REH - 72.jpg', 
      alt: 'Angel Fish', 
      thumbImage: 'assets/images/artwork/paintings/Angel Fish REH - 72.jpg', 
      title: 'Angel Fish'
    },
    { id: 2,
      specificId: 'painting2', 
      image: 'assets/images/artwork/paintings/Elephant -72.jpg', 
      alt: 'Elephant', 
      thumbImage: 'assets/images/artwork/paintings/Elephant -72.jpg', 
      title: 'Elephant'
    },
    { id: 3,
      specificId: 'painting3', 
      image: 'assets/images/artwork/paintings/Bike - 72.jpg', 
      alt: 'Bike', 
      thumbImage: 'assets/images/artwork/paintings/Bike - 72.jpg', 
      title: 'Bike'
    },
    { id: 4,
      specificId: 'painting4', 
      image: 'assets/images/artwork/paintings/Orange - 72.jpg', 
      alt: 'Orange', 
      thumbImage: 'assets/images/artwork/paintings/Orange - 72.jpg', 
      title: 'Orange'
    },
    { id: 5,
      specificId: 'painting5', 
      image: 'assets/images/artwork/paintings/Moms ptg - 72.jpg', 
      alt: 'Mom\'s painting', 
      thumbImage: 'assets/images/artwork/paintings/Moms ptg - 72.jpg', 
      title: 'Mom\'s painting'
    }
  ]