<h2>Career</h2>

<div class="main-container">
    <div class="top-row row d-flex align-items-center">
        <div class="col-md-9 col-sm-12">
            <div class="box-container row col-md-12">
                <h3 class="objective col-md-3">Career Objective:</h3>
                <p class="col-md-9">I am a web designing professional and enthusiast. I am pursuing full stack development because I love to learn and because I am passionate about how websites work. I am also an artist by training and disposition and believe this skill helps me to conceptualize websites from theory to realization.</p>
            </div>

            <div class="box-container row col-md-12">
                <h3 class="summary col-md-3">Career Summary:</h3>
                <p class="col-md-9">For the last eight years I have successfully advanced from Front End Developer to becoming a Full Stack Developer. While at Clayton Homes, I consistently received coveted ACE awards for high performance, customer satisfaction, and collaboration. At StoragePug, I was nicknamed “eagle eye” Tanya for my attention to detail.</p>
            </div>
        </div>

        <div class="img-container col-md-3 col-sm-12">
            <a href="https://www.claytonhomes.com/dream-to-reality/" target="_blank">
                <img class="img-fluid dream-img" src="../../assets/images/dream-to-reality.png" alt="Dream to Reality" />
            </a>
        </div>
    </div>
    <div class="second-row row">
        <app-knowledge></app-knowledge>
    </div>
    <div class="third-row row">
        <app-experience></app-experience>
    </div>
    <div class="fourth-row row box-container ">
        <div class="education-container">
            <h3>Education</h3>
            <div class="entry">
                <h4>Coding Dojo - Bootcamp</h4>
                <p>Black Belt - MERN Stack</p>
            </div>
            <div class="entry">
                <h4>Pellissippi State Technical Comminity College, Knoxville, TN</h4>
                <p>Associate of Applied Science Degree in Media Technologies - Web</p>
            </div>
            <div class="entry">
                <h4>University of Tennessee, Knoxville, TN</h4>
                <p>Completed three years of coursework (Painting/Drawing major)</p>
            </div>
        </div>
    </div>
</div>