import { Project } from '../classes/project';

export const PROJECTS: Project[] = [
  { id: 1, 
    name: 'GrocerySwap (Neighborhood-Bartering)', 
    note: 'Group Project', 
    description: 'A MERN based application for trading local food products to reduce waste and create eco-friendly food swaps in local neighborhoods',
    url: 'https://github.com/RobertsonTanya/Neighborhood-Bartering',
    details: [
    'Work with two other students in a team effort to design a full CRUD application with functional create, read, update, and delete features',
    'Integrated React with one-to-many relationships using MongoDB to create trade commenting capabilities',
    'Created login and registration using back end validations and web tokens for a secure and personal user experience',
    'Implemented Bcrypt\'s password hashing to secure sensitive user account information',
    'Developed and linked 3 models using NoSQL, MongoDB, enabling users to comment and track their trades',
    'Implemented, designed, and styled CSS for completely responsive design'
  ] },
  { id: 2, 
    name: 'Recipes', 
    note: 'Solo Project', 
    description: 'A MERN based application for collecting and using recipes',
    url: 'https://github.com/RobertsonTanya/Recipes',
    details: [
    'Created a full CRUD application with functional create, read, update, and delete features from scratch',
    'Leveraged React with fully responsive CSS to populate a list of all recipes and their respective detailed pages',
    'Developed full back end using an Express framework with a MongoDB database'
  ] },
];