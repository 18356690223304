<div class="main-container">
    <h2>Technical Experience</h2>

    <ul *ngFor="let project of projects" class="project-container">
        <li class="list-unstyled">
            <h3><a [href]='project.url' target="_blank">{{ project.name }}</a> | {{project.note}}</h3>
            <div class="details-container">
                <h4>{{ project.description }}</h4>
                <ul *ngFor="let detail of project.details">
                    <li>{{ detail }}</li>
                </ul>
            </div>
        </li>
    </ul>
</div>
