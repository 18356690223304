<div id="artwork" class="main-container mt-4">
    <div class="paintings">
        <h2>Paintings:</h2>
        <div class="row">
            <ng-image-slider [images]="paintings" #nav></ng-image-slider>
        </div>
    </div>
    <div class="drawings">
        <h2>Drawings:</h2>
        <div class="row">
            <ng-image-slider [images]="drawings" #nav></ng-image-slider>
        </div>

        <!-- <div class="row slideshow">
            <div class="image-container">
                <a class="prev icons prev-icon" (click)="prevDrawing()">‹</a>
                <div id="images" class="images">
                    <ul>
                        <li class="image" *ngFor="let drawing of drawings;" (click)="selectDrawing(drawing.id)">
                            <div [id]="drawing.specificId+'-thumb'"class="thumb-container">
                                <img class="thumb" [src]="drawing.thumbImage" (click)="showArtwork(drawing.specificId)" [alt]="drawing.alt" />
                                <caption dir="ltr">{{ drawing.title }}</caption>
                            </div>
                            <div [id]="drawing.specificId" (click)="hideArtwork(drawing.specificId)" class="single-image">
                                <img [src]="drawing.image" [alt]="drawing.alt" />
                            </div>
                        </li>
                    </ul>
                </div>
                <a class="next icons next-icon" (click)="nextDrawing()">›</a>
            </div>
        </div> -->
    </div>
</div>