<h2>COMPUTER PROGRAMS & KNOWLEDGE BASE</h2>


<div class="main-container row">
    <div class="img-container col-md-4">
        <a href="https://www.storeatsentinel.com" target="_blank">
            <img src="assets/images/sentinel.png" class="sentinel-img img-fluid" alt="Sentinel homepage" />
        </a>
    </div>
    <div class="box-container col-md-8">
        <h3>Certification: Web Authoring</h3>
        <div class="input-container d-flex align-items-center justify-content-center">
          <ul>
              <li *ngFor="let listItem of knowledgeData">{{ listItem }}</li>
              <li>PC & Macintosh Operating Systems</li>
          </ul>
    </div>
</div>
